<script setup>
import Icon from '@/Components/Icon.vue';
import BlueArrowDown from '@i/blue-dropdown-arrow-down.svg';

const props = defineProps({
    headers: Array,
    sortBy: {
        type: String,
        default: '',
    },
    sortByDesc: {
        type: Boolean,
        default: '',
    },
});

const emit = defineEmits(['update:sortBy', 'update:sortByDesc']);

const emitUpdates = (sortBy) => {
    if (sortBy !== props.sortBy) {
        emit('update:sortBy', sortBy);
        emit('update:sortByDesc', false);
    } else {
        emit('update:sortByDesc', !props.sortByDesc);
    }
};
</script>

<template >
    <div
        v-for="header in headers"
        :key="header.label"
        @click="emitUpdates(header.sortBy)"
        :class="{ 'cursor-pointer': header.sortBy }"
    >
        <div class="flex gap-2">
            {{ header.label }}
            <Icon
                v-if="header.sortBy"
                :icon="BlueArrowDown"
                class="relative right-0 w-4 h-auto"
                :class="{ 'rotate-180': !props.sortByDesc }"
            />
        </div>
    </div>
</template>
